<template>
  <plays />
</template>

<script>
import Plays from "../components/Plays";

export default {
  name: "Home",

  components: {
    Plays,
  },
};
</script>
