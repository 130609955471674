<template>
  <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
    {{ snackText }}

    <template v-slot:action="{ attrs }">
      <v-btn v-bind="attrs" text @click="snack = false"> Chiudi </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "Snack",
  data: () => ({
    snack: false,
    snackColor: "",
    snackText: "",
  }),
  created() {
    this.$events.$on("snack", this.setSnack);
  },
  destroyed() {
    this.$events.$off("snack", this.setSnack);
  },
  methods: {
    setSnack({ text, color }) {
      this.snack = true;
      this.snackColor = color;
      this.snackText = text;
    },
  },
};
</script>
