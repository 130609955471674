import Vue from "vue";
import Vuex from "vuex";
// import db from "@/example.json";
import { API } from "aws-amplify";
import awsconfig from "@/aws-exports";
import { listGames } from "../graphql/queries";
import { updateGame, createGame, deleteGame } from "../graphql/mutations";
import { SET_PLAYS, UPDATE_QUESTION } from "./mutation-types";
Vue.use(Vuex);

API.configure(awsconfig);
export default new Vuex.Store({
  state: {
    plays: [],
  },
  mutations: {
    [SET_PLAYS](state, payload) {
      state.plays = payload.filter((p) => !p._deleted);
    },
    [UPDATE_QUESTION](state, payload) {
      state.plays = state.plays.map((p) => {
        if (p.id === payload.gameID) {
          p.questions[payload.questionIndex] = payload;
        }
        return p;
      });
    },
  },
  actions: {
    async saveQuestion({ state, commit }, question) {
      console.log(question);
      await API.graphql({
        query: updateGame,
        variables: {
          input: {
            id: question.gameID,
            questions: state.plays
              .find((p) => p.id === question.gameID)
              .questions.map((q, i) => {
                if (i === question.questionIndex) {
                  return JSON.stringify(question);
                } else {
                  return JSON.stringify(q);
                }
              }),
          },
        },
      });
      commit(UPDATE_QUESTION, question);
    },
    async addPlays({ dispatch }, plays) {
      for (const p of plays) {
        await API.graphql({
          query: createGame,
          variables: {
            input: {
              gameID: p.id,
              questions: p.questions.map((q) => JSON.stringify(q)),
            },
          },
        });
      }
      await dispatch("getPlays");
    },
    async updatePlays({ dispatch, getters }, plays) {
      for (const p of plays) {
        await API.graphql({
          query: updateGame,
          variables: {
            input: {
              ...p,
              gameID: p.id,
              id: getters.plays.find((sp) => sp.gameID === p.id).id,
              questions: p.questions.map((q) => JSON.stringify(q)),
            },
          },
        });
      }
      await dispatch("getPlays");
    },
    async getPlays({ commit }) {
      const plays = await API.graphql({
        query: listGames,
      });
      commit(
        SET_PLAYS,
        plays.data.listGames.items.map((g) => ({
          ...g,
          questions: g.questions.map((q) => JSON.parse(q)),
        }))
      );
    },
    async deletePlays({ dispatch }, plays) {
      for (const play of plays) {
        console.log(play);
        await API.graphql({
          query: deleteGame,
          variables: {
            input: {
              id: play.id,
              _version: play.version,
            },
          },
        });
      }
      await dispatch("getPlays");
    },
  },
  getters: {
    plays: (state) =>
      state.plays.map((p) => ({
        id: p.id,
        gameID: p.gameID,
        from: p.questions[0].suggestion,
        to: p.questions[p.questions.length - 1].answer,
        played: p.played || 0,
        won: p.won || 0,
        questions: p.questions.map((q, i) => ({ ...q, id: `${p.id}${i}` })),
        wonPercentage: ((100 * (p.won || 0)) / (p.played || 0)).toFixed(1),
      })),
  },
});
