<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center title">Solo1Lettera - Admin</div>

      <v-spacer></v-spacer>
      <dark-light-toggle />
      <v-btn @click="onLogout" text>
        <span class="mr-2">Logout</span>
        <v-icon>mdi-exit-to-app</v-icon>
      </v-btn>
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        bottom
        color="teal accent-3"
      ></v-progress-linear>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
    <snack />
  </v-app>
</template>

<script>
import DarkLightToggle from "./components/DarkLightToggle.vue";
import Snack from "./components/Snack.vue";
export default {
  name: "App",
  components: { DarkLightToggle, Snack },
  data: () => ({
    loading: false,
  }),
  created() {
    this.$store.dispatch("getPlays");
  },
  methods: {
    onLogout() {
      this.$msal.signOut();
    },
  },
};
</script>
