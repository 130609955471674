<template>
  <div>
    <v-btn @click="darkmode = !darkmode" icon
      ><v-icon v-if="darkmode">mdi-brightness-6</v-icon
      ><v-icon v-else>mdi-brightness-2</v-icon></v-btn
    >
  </div>
</template>
<script>
export default {
  name: "DarkLightToggle",
  data() {
    return {
      darkmode: false,
    };
  },
  watch: {
    darkmode() {
      this.handledarkmode();
    },
  },
  created() {
    this.darkmode =
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches;
    if (process.browser) {
      if (localStorage.getItem("DarkMode")) {
        const cookieValue = localStorage.getItem("DarkMode") === "true";
        this.darkmode = cookieValue;
      } else {
        this.handledarkmode();
      }
    }
  },
  methods: {
    handledarkmode() {
      if (process.browser) {
        if (this.darkmode === true) {
          this.$vuetify.theme.dark = true;
          localStorage.setItem("DarkMode", true);
        } else if (this.darkmode === false) {
          this.$vuetify.theme.dark = false;
          localStorage.setItem("DarkMode", false);
        }
      }
    },
  },
};
</script>
