<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-data-table
          v-model="selectedRow"
          show-select
          :headers="headers"
          :items="plays"
          show-expand
          single-expand
          :items-per-page="20"
          :footer-props="{
            'items-per-page-options': [10, 20, -1],
          }"
        >
          <template slot="body.append">
            <tr>
              <th>Total</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th>{{ totGiocate }}</th>
              <th>{{ totVinte }}</th>
              <th>{{ totPerc }}</th>
            </tr>
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Giocate</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-dialog v-model="importDialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Importa giocate
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">Importa giocate</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col>
                          <v-radio-group v-model="updateOrSkip">
                            <v-radio
                              label="Ignora giocate già esistenti"
                              value="skip"
                            ></v-radio>
                            <v-radio
                              label="Sovrascrivi se già esistenti"
                              value="update"
                            ></v-radio>
                          </v-radio-group>
                          <v-file-input
                            v-model="files"
                            accept=".xml"
                            small-chips
                            counter
                            multiple
                            show-size
                            truncate-length="15"
                          ></v-file-input>
                          <v-progress-linear
                            v-if="importing"
                            v-model="progress"
                            height="25"
                            class="no-transition"
                          >
                            <strong>{{ Math.ceil(progress) }}%</strong>
                          </v-progress-linear>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Annulla
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="importPlays"
                      :loading="importing"
                    >
                      Importa
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td class="px-0" :colspan="headers.length">
              <questions :questions="item.questions" :gameID="item.id" />
            </td>
          </template>
          <template v-slot:footer.prepend>
            <v-dialog v-model="deleteDialog" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :disabled="selectedRow.length === 0"
                  @click="deleteDialog = true"
                  color="error"
                >
                  <v-icon> mdi-delete </v-icon>
                  Elimina giocate selezionate
                </v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">Elimina giocate</span>
                </v-card-title>

                <v-card-text>
                  Sei sicuro di voler eliminare le giocate selezionate ?
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="deleteDialog = false"
                  >
                    Annulla
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="deletePlays"
                    :loading="deletePlaysLoading"
                  >
                    Elimina
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import mapper from "@/utils/mapper.js";
import { mapGetters } from "vuex";
import Questions from "@/components/Questions.vue";
export default {
  components: { Questions },
  name: "Plays",
  computed: {
    ...mapGetters(["plays"]),
    totGiocate() {
      return this.plays.reduce((acc, curr) => acc + curr.played, 0);
    },
    totVinte() {
      return this.plays.reduce((acc, curr) => acc + curr.won, 0);
    },
    totPerc() {
      return this.totGiocate > 0
        ? ((this.totVinte / this.totGiocate) * 100).toFixed(2)
        : 0;
    },
  },
  data: () => ({
    selectedRow: [],
    deleteDialog: false,
    updateOrSkip: "skip",
    progress: 0,
    importDialog: false,
    importing: false,
    deletePlaysLoading: false,
    newFiles: [],
    updateFiles: [],
    files: [],
    headers: [
      {
        text: "ID giocata",
        align: "start",
        value: "gameID",
      },
      { text: "Da", value: "from" },
      { text: "A", value: "to" },
      { text: "Giocata n volte", value: "played" },
      { text: "Vinta n volte", value: "won" },
      { text: "% vittorie", value: "wonPercentage" },
    ],
  }),
  methods: {
    importPlays() {
      this.importing = true;
      const that = this;
      const total = this.files.length;
      let loaded = 0;
      this.files.forEach((f) => {
        const fileReader = new FileReader();
        fileReader.readAsText(f);
        fileReader.onload = async function () {
          // do something on FileReader onload
          const parsed = await mapper(fileReader.result);
          if (that.plays.some((p) => p.gameID === parsed.id)) {
            that.updateFiles.push(parsed);
          } else {
            that.newFiles.push(parsed);
          }

          loaded++;
          if (loaded == total) {
            that.onAllFilesLoaded();
          }
        };

        fileReader.onprogress = function (data) {
          if (data.lengthComputable) {
            that.progress =
              (parseInt((data.loaded / data.total) * 100, 10) * loaded) / total;
          }
        };
      });
    },
    close() {
      this.progress = 0;
      this.importing = false;
      this.importDialog = false;
    },
    async onAllFilesLoaded() {
      await this.$store.dispatch("addPlays", this.newFiles);
      if (this.updateOrSkip === "update") {
        await this.$store.dispatch("updatePlays", this.updateFiles);
      }
      this.progress = 0;
      this.importing = false;
      this.importDialog = false;
    },
    async deletePlays() {
      this.deletePlaysLoading = true;
      await this.$store.dispatch("deletePlays", this.selectedRow);
      this.deletePlaysLoading = false;
      this.deleteDialog = false;
    },
  },
};
</script>
<style lang="scss" scoped></style>
