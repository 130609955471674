import Vue from "vue";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
const MSALPlugin = {
  install(Vue, { auth, cache, request }) {
    const myMSALObj = new PublicClientApplication({ auth, cache });
    myMSALObj.addEventCallback(
      (event) => {
        // set active account after redirect
        if (
          event.eventType === EventType.LOGIN_SUCCESS &&
          event.payload.account
        ) {
          const account = event.payload.account;
          myMSALObj.setActiveAccount(account);
        }
      },
      (error) => {
        console.log("error", error);
      }
    );
    myMSALObj
      .handleRedirectPromise()
      .then(() => {
        // Check if user signed in
        const account = myMSALObj.getActiveAccount();
        if (!account) {
          // redirect anonymous user to login page
          myMSALObj.loginRedirect();
        }
      })
      .catch((err) => {
        // TODO: Handle errors
        console.log(err);
      });
    const getAccount = () => myMSALObj.getActiveAccount();
    const signIn = function () {
      myMSALObj
        .loginRedirect(request)
        .then((loginResponse) => {
          console.log("id_token acquired at: " + new Date().toString());
          console.log(loginResponse);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const signOut = function () {
      sessionStorage.clear();
      myMSALObj.logoutRedirect({
        account: myMSALObj.getActiveAccount(),
      });
    };
    const isAuthenticated = function () {
      return getAccount() !== undefined;
    };
    const name = () => getAccount().name.replace(",", "");
    const user = () => {
      return {
        name: getAccount().name,
        id: getAccount().accountIdentifier,
      };
    };
    const userID = () => user().id;
    Vue.msal = Vue.prototype.$msal = {
      signIn,
      signOut,
      isAuthenticated,
      getAccount,
      name,
      user,
      userID,
    };
  },
};

Vue.use(MSALPlugin, {
  auth: {
    clientId:
      process.env.VUE_APP_CLIENT_ID || "4180c00f-adfb-4c7d-a6bd-8108f800e99f",
    authority:
      process.env.VUE_APP_AUTH_URL ||
      "https://login.microsoftonline.com/2598639a-d083-492d-bdbe-f1dd8066b03a",

    redirectUri: window.location.origin,
  },
  request: {
    redirectUri: window.location.origin,
    postLogoutRedirectUri: "https://www.rsi.ch",
    scopes: ["openid", "User.Read"],
  },
});
