import * as Diff from "diff";
import _ from "lodash";
import xml2js from "xml2js";
function getRandomChars(length, avoidChars) {
  let randomChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".replace(
    new RegExp(avoidChars.join("|")),
    ""
  );
  let result = [];
  for (var i = 0; i < length; i++) {
    const rndChar = randomChars.charAt(
      Math.floor(Math.random() * randomChars.length)
    );
    result.push(rndChar);
    randomChars = randomChars.replace(rndChar, "");
  }
  return result;
}

const mapGame = (g) => ({
  id: g.QUESTION.QuestionID,
  questions: g.QUESTIONWORD.sort(
    (a, b) => a.QuestionWordPos - b.QuestionWordPos
  )
    .map((q, i, a) => {
      const qwn = q.QuestionWordName.split("|");
      const question = qwn.shift();
      const answer = qwn.pop().toLocaleUpperCase();
      const suggestion =
        i > 0
          ? a[i - 1].QuestionWordName.split("|").pop().toLocaleUpperCase()
          : "";
      let options = _.isEmpty(suggestion)
        ? []
        : Diff.diffChars(suggestion, answer).reduce((acc, p) => {
            if (p.added) acc.push(p.value);
            return acc;
          }, []);
      options = _.shuffle([
        ...options,
        ...getRandomChars(10 - options.length, options),
      ]);
      return {
        question,
        answer,
        suggestion,
        options,
      };
    })
    .slice(1),
});

export default async (xmlString) => {
  const parser = new xml2js.Parser({ explicitArray: false });
  const res = await parser.parseStringPromise(xmlString);
  return mapGame(res.Export);
};
