<template>
  <v-data-table
    class="rounded-0"
    :headers="questionsHeaders"
    :items="questions"
    :items-per-page="15"
    disable-sort
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Domande</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedQuestion.question"
                      label="Domanda"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      :value="editedQuestion.suggestion"
                      @input="editedQuestion.suggestion = $event.toUpperCase()"
                      label="Parola iniziale"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      :value="editedQuestion.answer"
                      @input="editedQuestion.answer = $event.toUpperCase()"
                      v-model="editedQuestion.answer"
                      label="Soluzione"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="10" md="8">
                    <v-combobox
                      v-model="editedQuestion.options"
                      :items="'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')"
                      multiple
                      label="Lettere disponibili"
                      chips
                      counter
                      :rules="[only10Chars]"
                      auto-select-first
                    ></v-combobox>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close"> Annulla </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
                :loading="saveLoading"
              >
                Salva
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:item.index="{ index }">
      {{ index + 1 }}
    </template>
    <template v-slot:item.actions="props">
      <v-icon
        small
        class="mr-2"
        @click="openEditDialog(gameID, props.index, props.item)"
      >
        mdi-pencil
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Questions",
  props: {
    questions: {
      default: [],
    },
    gameID: [Number, String],
  },
  computed: {
    formTitle() {
      return this.editedQuestion.questionIndex === -1
        ? "Nuova domanda"
        : "Modifica domanda";
    },
  },
  watch: {
    dialog(v) {
      !v && this.initDialog();
    },
  },
  data: () => ({
    saveLoading: false,
    only10Chars: (v) => v.length === 10 || "Scegliere esattamente 10 lettere",
    dialog: false,
    editedQuestion: {},
    questionsHeaders: [
      {
        text: "#",
        value: "index",
      },
      { text: "Domanda", value: "question" },
      { text: "Da", value: "suggestion" },
      { text: "A", value: "answer" },
      { text: "Lettere", value: "options" },
      { text: "Azioni", value: "actions" },
    ],
  }),
  methods: {
    ...mapActions(["saveQuestion", "deleteQuestion"]),
    initDialog() {
      this.editedQuestion = {
        gameID: -1,
        questionIndex: -1,
        question: "",
        suggestion: "",
        answer: "",
        options: [],
      };
      this.saveLoading = false;
    },
    async save() {
      this.saveLoading = true;
      await this.saveQuestion(this.editedQuestion);
      this.dialog = false;
      this.$events.$emit("snack", {
        text: "Domanda salvata",
        color: "success",
      });
    },
    close() {
      this.initDialog();
      this.dialog = false;
    },
    openEditDialog(gameID, questionIndex, item) {
      this.editedQuestion = { ...item, gameID, questionIndex };
      this.dialog = true;
    },
  },
};
</script>

<style></style>
